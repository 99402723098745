import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  constructor(private http: HttpClient) {
    this.checkIsLoggedIn();
  }

  login(email: string, password: string): Observable<any> {
    const url = `${environment.apiUrl}login`;
    const body = { email, password };

    return this.http.post(url, body).pipe(
      tap((response) => {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('user_name', response.user_name);
        localStorage.setItem('access', JSON.stringify(response));
        // localStorage.setItem('is_super_admin', response.is_super_admin);
        // localStorage.setItem('vod', response.vod);
        // localStorage.setItem('live_tv', response.live_tv);
        // localStorage.setItem('advertising', response.advertising);
        this.isLoggedInSubject.next(true);
      })
    );
  }

  register(username: string, email: string, password: string): Observable<any> {
    const url = `${environment.apiUrl}register`;
    const body = { username, email, password };

    return this.http.post(url, body).pipe(
      tap((response) => {
        console.log(response);
      })
    );
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_name');
    localStorage.removeItem('access');
    this.isLoggedInSubject.next(false);
  }

  checkIsLoggedIn(): void {
    const token = localStorage.getItem('access_token');
    this.isLoggedInSubject.next(!!token);
  }

  GetAdmins() {
    return this.http.get<any>(`${environment.apiUrl}users`);
  }

  updateUser(data: any) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });

    return this.http.put<any>(`${environment.apiUrl}user`, data, { headers: headers });
  }
}
